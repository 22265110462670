import {
  Box,
  Flex,
  LayoutProps,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Portal,
  useDisclosure
} from '@chakra-ui/react';
import size from 'lodash/size';
import { ReactNode, useState } from 'react';
import { Button } from '../../../../../../../../helpers/Button';
import { Loader } from '../../../../../../../../helpers/Loader';
import { Text } from '../../../../../../../../helpers/Text';
import { ChevronDownIcon } from '../../../../../../../../icons/ChevronDownIcon';
import {
  JobCandidateId,
  JobCandidateNanoId
} from '../../../../../../../jobCandidates/jobCandidatesTypes';
import { usePaginatedWorkflowStages } from '../../../../../../../workflowStages/hooks/usePaginatedWorkflowStages';
import {
  FetchWorkflowStagesResponse,
  fetchWorkflowStagesQuery
} from '../../../../../../../workflowStages/queries/fetchWorkflowStages.query';
import { WorkflowStagesCache } from '../../../../../../../workflowStages/WorkflowStagesCache';
import {
  FetchWorkflowStagesFilters,
  FetchWorkflowStagesSort,
  WorkflowStageFields
} from '../../../../../../../workflowStages/workflowStagesTypes';
import { JobId, JobNanoId } from '../../../../../../jobsTypes';
import { JobCandidateDetailsPageWorkflowStage } from '../../../../ViewJobCandidateDetailsPage.types';

interface ViewJobCandidateDetailsStagesDropdownProps {
  showCandidateCount?: boolean;
  showActivitiesCount?: boolean;
  currentWorkflowStage: JobCandidateDetailsPageWorkflowStage;
  onSelectStage?: (stage: JobCandidateDetailsPageWorkflowStage) => void;
  dropdownWidth?: LayoutProps['width'];
  rightElement?: ReactNode;
  job: {
    id: JobId;
    nanoId: JobNanoId;
  };
  jobCandidate?: {
    id: JobCandidateId;
    nanoId: JobCandidateNanoId;
  };
}

export default function ViewJobCandidateDetailsStagesDropdown({
  job,
  rightElement,
  onSelectStage,
  dropdownWidth = 64,
  showCandidateCount,
  showActivitiesCount,
  currentWorkflowStage
}: ViewJobCandidateDetailsStagesDropdownProps) {
  const { workflowStages, workflowStagesIsLoading, workflowStagesIsFetched } =
    usePaginatedWorkflowStages<FetchWorkflowStagesResponse>({
      query: fetchWorkflowStagesQuery,
      cacheKey: WorkflowStagesCache.jobIndexCacheKey(job.nanoId),
      initialFilters: {
        [WorkflowStageFields.JOB_ID]: { operator: 'eq', value: job.id }
      } as unknown as FetchWorkflowStagesFilters,
      initialSort: {
        [WorkflowStageFields.ARRANGEMENT]: { ascending: true }
      } as unknown as FetchWorkflowStagesSort
    });

  const [selectedStage, setSelectedStage] =
    useState<JobCandidateDetailsPageWorkflowStage>(currentWorkflowStage);

  const { isOpen, onToggle, onClose } = useDisclosure();

  const changeStage = (stage: JobCandidateDetailsPageWorkflowStage) => {
    !!onSelectStage && onSelectStage(stage);
    setSelectedStage(stage);

    onClose();
  };

  return (
    <Popover isOpen={isOpen} onClose={onClose} isLazy>
      <PopoverTrigger>
        <Flex alignItems="stretch" w="full">
          <Button
            p={0}
            w="full"
            h="100%"
            minH={6}
            minW={0}
            size="small"
            whiteSpace="nowrap"
            textOverflow="ellipsis"
            wordBreak="break-all"
            onClick={(e) => {
              e.stopPropagation();
              onToggle();
            }}
            border="none"
            textAlign="left"
            boxShadow="none"
            fontSize="inherit"
            hierarchy="unstyled"
            aria-label="Dropdown"
          >
            <Flex
              w="full"
              gap={2}
              whiteSpace="nowrap"
              wordBreak="break-all"
              alignItems="center"
              fontSize="inherit"
            >
              <Box w={2.5} h={2.5} bg={selectedStage?.color} rounded="full" />

              <Text
                noOfLines={1}
                whiteSpace="nowrap"
                fontWeight="medium"
                wordBreak="break-all"
                textOverflow="ellipsis"
                textTransform="capitalize"
              >
                {selectedStage?.name}
              </Text>

              {rightElement}
              <ChevronDownIcon ml="auto" fill="gray.500" />
            </Flex>
          </Button>
        </Flex>
      </PopoverTrigger>

      <Portal>
        <PopoverContent p={1} w={dropdownWidth} overflow="hidden">
          <Flex flexDir="column" overflow="auto">
            {workflowStagesIsLoading && (
              <Loader size={8} loaded={workflowStagesIsFetched} />
            )}

            {workflowStages.map((stage) => (
              <Button
                px={2}
                py={1}
                gap={2}
                h="auto"
                role="group"
                size="small"
                display="flex"
                color="inherit"
                textAlign="left"
                lineHeight={1.5}
                fontSize="inherit"
                borderRadius="none"
                fontWeight="inherit"
                hierarchy="unstyled"
                textTransform="capitalize"
                justifyContent="flex-start"
                isDisabled={selectedStage?.nanoId === stage.nanoId}
                onClick={() => {
                  changeStage(stage);
                }}
                value={stage.nanoId}
                key={stage.nanoId}
                _hover={{
                  bg: 'gray.100'
                }}
              >
                <Box w={2.5} h={2.5} bg={stage.color} rounded="full" />

                {stage.name}

                {showCandidateCount && (
                  <Text as="span" textStyle="body2Medium">
                    ({size(stage.allJobCandidates)})
                  </Text>
                )}

                {showActivitiesCount && (
                  <>
                    <Text
                      as="span"
                      opacity={0}
                      textStyle="body2Regular"
                      _groupHover={{
                        opacity: 1
                      }}
                    >
                      -
                    </Text>

                    <Text
                      as="span"
                      opacity={0}
                      transitionDuration="slow"
                      textStyle="body2Regular"
                      _groupHover={{
                        opacity: 1
                      }}
                    >
                      {size(stage.workflowStageTasks)} activities
                    </Text>
                  </>
                )}
              </Button>
            ))}
          </Flex>
        </PopoverContent>
      </Portal>
    </Popover>
  );
}
