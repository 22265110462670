import { Flex, HStack, useDisclosure } from '@chakra-ui/react';
import { Text } from '../../../../../../../../helpers/Text';
import { ClockIcon } from '../../../../../../../../icons/ClockIcon';
import {
  JobId,
  JobJobTitle,
  JobName,
  JobNanoId
} from '../../../../../../jobsTypes';
import {
  JobCandidateDetailsJobCandidate,
  JobCandidateDetailsPageWorkflowStage
} from '../../../../ViewJobCandidateDetailsPage.types';
import { ViewJobCandidateDetailsStagesDropdown } from '../ViewJobCandidateDetailsStagesDropdown';
import { ViewJobCandidateInterviewScoreCardModal } from '../ViewJobCandidateInterviewScoreCardModal';

interface ViewJobCandidateDetailsStageSectionProps {
  job: { id: JobId; nanoId: JobNanoId; jobTitle: JobJobTitle; name: JobName };
  currentWorkflowStage: JobCandidateDetailsPageWorkflowStage;
  jobCandidate?: JobCandidateDetailsJobCandidate;
  requiresCredit?: boolean;
}

export default function ViewJobCandidateDetailsStageSection({
  job,
  jobCandidate,
  requiresCredit,
  currentWorkflowStage
}: ViewJobCandidateDetailsStageSectionProps) {
  const {
    isOpen: isOpenScoreCard,
    onOpen: openScoreCard,
    onClose: closeScoreCard
  } = useDisclosure();

  const changeWorkflowStage = (stage: JobCandidateDetailsPageWorkflowStage) => {
    if (stage.name === 'Interview') {
      openScoreCard();
    }

    /* toast({
      title: `${startCase(selectedStage?.name)} stage selected`,
      status: 'success',
      position: 'top-right',
      duration: 2000,
      isClosable: true
    }); */
  };

  return (
    <>
      <Flex p={3} w="full" gap={2} flexDir="column">
        <Flex
          h={8}
          w="full"
          pl={2.5}
          pr={1.5}
          bg="white"
          color="gray.700"
          border="1px solid"
          borderColor="gray.300"
          borderRadius="base"
          fontWeight="normal"
        >
          <ViewJobCandidateDetailsStagesDropdown
            currentWorkflowStage={currentWorkflowStage}
            onSelectStage={changeWorkflowStage}
            showActivitiesCount
            job={job}
          />
        </Flex>

        <Flex h={6} alignItems="center">
          <HStack>
            <ClockIcon w={4} h={4} />
            <Text textStyle="body2Regular">{currentWorkflowStage?.name}</Text>

            {/* <Tag bg="red.500" color="white" size="sm" borderRadius="base">
            Stagnant
          </Tag> */}
          </HStack>
        </Flex>
      </Flex>

      <ViewJobCandidateInterviewScoreCardModal
        jobCandidate={jobCandidate}
        requiresCredit={requiresCredit}
        isOpenScoreCard={isOpenScoreCard}
        closeScoreCard={closeScoreCard}
        job={job}
      />
    </>
  );
}
