import { Avatar } from '@chakra-ui/avatar';
import { Box, Flex, Stack } from '@chakra-ui/layout';
import { Tag, useDisclosure } from '@chakra-ui/react';
import { Fragment } from 'react';
import { AlarmIcon } from '../../../../icons/AlarmIcon';
import { CalendarIcon } from '../../../../icons/CalendarIcon';
import { MessageIcon } from '../../../../icons/MessageIcon';
import { MoreIcon } from '../../../../icons/MoreIcon';
import { IconButton } from '../../../buttons/IconButton';
import { RelativeDateHelper } from '../../../RelativeDateHelper';
import { Text } from '../../../Text';
import useNotificationToast from '../../../../common/hooks/utils/useNotificationToast/useNotificationToast';

interface SingleNotificationProps {
  title: string;
  description: string;
  isUnread?: boolean;
  avatar?: string;
  date: string;
  meta?: SingleNotificationMeta;
}

export interface SingleNotificationMeta {
  type?: 'comment' | 'reminder' | 'schedule';
  name?: string;
}

const SingleNotification = ({
  title,
  description,
  isUnread,
  avatar,
  date,
  meta
}: SingleNotificationProps) => {
  const { addToast } = useNotificationToast();

  const {
    isOpen: isHovered,
    onOpen: onMouseHover,
    onClose: onMouseLeave
  } = useDisclosure();

  const openNotification = () => {
    addToast({
      title: title,
      name: meta?.name,
      description: description,
      date: date,
      id: ''
    });
  };

  return (
    <Flex
      p={4}
      gap={2}
      bg="white"
      flexDir="row"
      cursor="pointer"
      onClick={openNotification}
      onMouseOver={onMouseHover}
      onMouseLeave={onMouseLeave}
    >
      <Avatar src={avatar} />

      <Stack spacing={0.5} w="100%">
        <Flex alignItems="center" gap={1} h={6}>
          <Text
            textStyle="body2Regular"
            fontWeight={isUnread ? 'medium' : 'normal'}
            color={isUnread ? 'gray.900' : 'gray.700'}
            textTransform="capitalize"
            noOfLines={1}
            flex={1}
          >
            {title}
          </Text>

          {isUnread ? (
            <Tag color="white" bg="#3E6EEB" size="sm" fontSize="2xs">
              New
            </Tag>
          ) : (
            <Fragment>
              {isHovered ? (
                <IconButton
                  hierarchy="link"
                  aria-label=""
                  ml="auto"
                  minW="0"
                  minH={0}
                  color="gray.600"
                >
                  <MoreIcon />
                </IconButton>
              ) : (
                <Text textStyle="body2Regular" color="gray.600" ml="auto">
                  <RelativeDateHelper date={date} />
                </Text>
              )}
            </Fragment>
          )}
        </Flex>

        {(meta?.name || meta?.type) && (
          <Flex alignItems="center" gap={1}>
            {meta.type === 'comment' && <MessageIcon h={4} w={4} />}
            {meta.type === 'reminder' && <AlarmIcon h={4} w={4} />}
            {meta.type === 'schedule' && <CalendarIcon h={4} w={4} />}

            <Text textStyle="body2Regular" noOfLines={2}>
              <Box
                as="span"
                color="gray.700"
                fontWeight="semibold"
                textTransform="capitalize"
              >
                {meta.type}
              </Box>
              {meta.type && meta.name && ' from '}
              <Box as="span" color="primary.500" fontWeight="semibold">
                {meta.name}
              </Box>
            </Text>
          </Flex>
        )}

        <Text
          textStyle="body2Regular"
          noOfLines={2}
          color={isUnread ? 'gray.900' : 'gray.600'}
        >
          {description}
        </Text>
      </Stack>
    </Flex>
  );
};

export default SingleNotification;
