import startCase from 'lodash/startCase';
import { ViewJobCandidateDetailsProfileCalendarEventType } from './components/ViewJobCandidateDetailsProfileCalendarEventItem/ViewJobCandidateDetailsProfileCalendarEventItem';
import { ViewJobCandidateDetailsProfileCalendarActivityType } from './components/ViewJobCandidateDetailsProfileCalendarActivityItem/ViewJobCandidateDetailsProfileCalendarActivityItem';

type CalendarEventsType = {
  [date: string]: ViewJobCandidateDetailsProfileCalendarEventType[];
};

const generateRandomEvents =
  (): ViewJobCandidateDetailsProfileCalendarEventType[] => {
    const eventTypes: Array<'meeting' | 'presentation' | 'seminar' | 'team'> = [
      'meeting',
      'presentation',
      'seminar',
      'team'
    ];

    const eventTitlesLeft = [
      'Client ',
      'Project',
      'Team Building',
      'Departmental',
      'Tech',
      'HR',
      'Team',
      'Strategy'
    ];

    const eventTitlesRight = [
      'Presentation',
      'Kickoff',
      'Activity',
      'Meeting',
      'Talk',
      'Session',
      'Standup'
    ];

    const generateEventTitle = (): string => {
      const leftIndex = Math.floor(Math.random() * eventTitlesLeft.length);
      const rightIndex = Math.floor(Math.random() * eventTitlesRight.length);

      return startCase(
        `${eventTitlesLeft[leftIndex]}${eventTitlesRight[rightIndex]}`
      );
    };

    const generateEventTime = (start?: string) => {
      let hours: number;
      let minutes: string;

      if (start) {
        const [startHour, startMinutePart] = start.split(':');
        const startMinute = parseInt(startMinutePart.split(' ')[0]);

        const randomDurationInMinutes = Math.floor(Math.random() * 151) + 30;
        const totalMinutes = startMinute + randomDurationInMinutes;

        hours = parseInt(startHour) + Math.floor(totalMinutes / 60);
        minutes = (totalMinutes % 60).toString().padStart(2, '0');

        if (hours > 4) {
          hours = 4;
          minutes = '45';
        }

        return `${hours}:${minutes} pm`;
      } else {
        hours = Math.floor(Math.random() * 6) + 9;
        minutes = Math.random() > 0.5 ? '00' : '30';
        return `${hours}:${minutes} am`;
      }
    };

    const generateEvent = (
      id: number
    ): ViewJobCandidateDetailsProfileCalendarEventType => {
      const eventType =
        eventTypes[Math.floor(Math.random() * eventTypes.length)];
      const startTime = generateEventTime();
      const endTime = generateEventTime(startTime);

      return {
        id: `evt-${Date.now()}-${id}`,
        title: generateEventTitle(),
        start: startTime,
        end: endTime,
        type: eventType,
        description: `This is a ${eventType} event.`
      };
    };

    const eventCount = Math.floor(Math.random() * 3) + 1;
    return Array.from({ length: eventCount }, (_, index) =>
      generateEvent(index)
    );
  };

const generateDateRangeEvents = (days: number): CalendarEventsType => {
  const events: CalendarEventsType = {};
  const today = new Date();

  for (let i = 0; i < days; i++) {
    const eventDate = new Date(today);
    eventDate.setDate(today.getDate() + i);

    const formattedDate = eventDate.toISOString().split('T')[0];

    events[formattedDate] = generateRandomEvents();
  }

  return events;
};

export const calendarEvents: CalendarEventsType = generateDateRangeEvents(30);

export const calendarReminders: ViewJobCandidateDetailsProfileCalendarActivityType[] =
  [
    {
      id: 'act-903542',
      due: 'Today @ 2:30pm',
      reminder: '2 hours',
      title: 'Cold Call',
      candidateName: 'Adam Braun',
      jobTitle: 'Senior Energy Engineer',
      status: 'in-progress',
      type: 'phone'
    },
    {
      id: 'act-346297',
      due: 'Today @ 4:00pm',
      reminder: '2 hours',
      title: 'Debrief',
      candidateName: 'Ryan Reprogle',
      jobTitle: 'Project Engineer II',
      status: 'not-started',
      type: 'calendar'
    },
    {
      id: 'act-405104',
      due: 'Tomorrow @ 10:00am',
      reminder: '12 hours',
      title: 'Send Offer',
      candidateName: 'Jonathan Basso',
      jobTitle: 'Electical Discipling Leader',
      status: 'not-started',
      type: 'email'
    },
    {
      id: 'act-748039',
      due: 'Tomorrow @ 3:30px',
      reminder: '1 day',
      title: 'Request Approval',
      candidateName: 'Joel Wicker',
      jobTitle: 'Mechanical Engineer',
      status: 'not-started',
      type: 'offer'
    }
  ];
