import {
  Avatar,
  Box,
  Collapse,
  ColorProps,
  Flex,
  HStack,
  Stack,
  useDisclosure
} from '@chakra-ui/react';
import { ReactNode } from 'react';
import { Button } from '../../../../../../../../../../helpers/Button';
import { TextareaField } from '../../../../../../../../../../helpers/forms/formFields/TextareaField';
import { Heading } from '../../../../../../../../../../helpers/Heading';
import { Text } from '../../../../../../../../../../helpers/Text';
import { AlarmIcon } from '../../../../../../../../../../icons/AlarmIcon';
import { ClockIcon } from '../../../../../../../../../../icons/ClockIcon';
import { ViewJobCandidateDetailsProfileCalendarActivityType } from '../ViewJobCandidateDetailsProfileCalendarActivityItem/ViewJobCandidateDetailsProfileCalendarActivityItem';
import { ViewJobCandidateDetailsProfileCalendarItemGrid } from '../ViewJobCandidateDetailsProfileCalendarItemGrid';
import { useChakraToast } from '../../../../../../../../../../helpers/useChakraToast';

type ViewJobCandidateDetailsProfileCalendarActivityPopoverProps = {
  icon: ReactNode;
  color: ColorProps['color'];
  togglePopover: () => void;
  event: ViewJobCandidateDetailsProfileCalendarActivityType;
};

export default function ViewJobCandidateDetailsProfileCalendarActivityPopover({
  icon,
  event,
  togglePopover
}: ViewJobCandidateDetailsProfileCalendarActivityPopoverProps) {
  const toast = useChakraToast();
  const { isOpen: isOpenComment, onToggle: onToggleComment } = useDisclosure();

  const markAsDone = () => {
    toast({ title: `Marked ${event.title} as done`, status: 'success' });
    togglePopover();
  };

  return (
    <Stack spacing={0}>
      <ViewJobCandidateDetailsProfileCalendarItemGrid
        iconAlignment="center"
        icon={
          <Flex h="full" alignItems="center">
            {icon}
          </Flex>
        }
      >
        <Flex flexDir="row" gap={3} alignItems="center">
          <Heading
            w="full"
            level="h3"
            fontSize="lg"
            noOfLines={1}
            fontWeight="medium"
            flex={1}
          >
            {event.title}
          </Heading>

          <Text
            as="span"
            bg="blue.500"
            color="white"
            display="inline-block"
            textStyle="body2Medium"
            textTransform="capitalize"
            borderRadius="base"
            px={2}
          >
            Pipeline
          </Text>
        </Flex>
      </ViewJobCandidateDetailsProfileCalendarItemGrid>

      <ViewJobCandidateDetailsProfileCalendarItemGrid
        icon={<Avatar size="xs" name="Adam Braun" />}
      >
        <HStack
          spacing={2}
          divider={
            <Flex as="span" border="none" fontSize="sm" fontWeight="semibold">
              /
            </Flex>
          }
        >
          <Text textStyle="body1Medium">Adam Braun</Text>

          <HStack>
            <Flex w={2} h={2} bg="blue.500" rounded="full" />
            <Text textStyle="body1Medium">Sourcing</Text>
          </HStack>
        </HStack>
      </ViewJobCandidateDetailsProfileCalendarItemGrid>

      <ViewJobCandidateDetailsProfileCalendarItemGrid icon={<ClockIcon />}>
        <Flex gap={4}>
          <Text lineHeight="taller" textStyle="body2Regular">
            Due: {event.due}
          </Text>

          <HStack>
            <AlarmIcon />
            <Text lineHeight="taller" textStyle="body2Regular">
              {event.reminder}
            </Text>
          </HStack>
        </Flex>
      </ViewJobCandidateDetailsProfileCalendarItemGrid>

      <ViewJobCandidateDetailsProfileCalendarItemGrid>
        <Flex flexDir="column">
          <Box>
            <Button hierarchy="link" size="small" onClick={onToggleComment}>
              {isOpenComment ? 'Remove' : 'Add'} comment
            </Button>
          </Box>

          <Collapse in={isOpenComment} animateOpacity>
            <Box p="1px" py={1}>
              <TextareaField />
            </Box>
          </Collapse>
        </Flex>
      </ViewJobCandidateDetailsProfileCalendarItemGrid>

      <ViewJobCandidateDetailsProfileCalendarItemGrid>
        <Flex>
          <Button px={4} size="small" ml="auto" onClick={markAsDone}>
            Mark as done
          </Button>
        </Flex>
      </ViewJobCandidateDetailsProfileCalendarItemGrid>
    </Stack>
  );
}
