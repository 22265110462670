import { useDisclosure } from '@chakra-ui/react';
import { useRouter } from 'next/router';
import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useMemo
} from 'react';

type ViewJobCandidateDetailsContextType = {
  openProfileOverview: () => void;
  closeProfileOverview: () => void;
  toggleProfileOverview: () => void;
  isProfileOverviewOpen: boolean;
  openComposeEmail: () => void;
  closeComposeEmail: () => void;
  toggleComposeEmail: () => void;
  isComposeEmailOpen: boolean;
  openAddComment: () => void;
  closeAddComment: () => void;
  toggleAddComment: () => void;
  isAddCommentOpen: boolean;
};

type ViewJobCandidateDetailsProviderProps = {
  children: ReactNode;
};

const ViewJobCandidateDetailsContext =
  createContext<ViewJobCandidateDetailsContextType>({
    openProfileOverview: () => {
      //
    },
    closeProfileOverview: () => {
      //
    },
    toggleProfileOverview: () => {
      //
    },
    isProfileOverviewOpen: true,
    openComposeEmail: () => {
      //
    },
    closeComposeEmail: () => {
      //
    },
    toggleComposeEmail: () => {
      //
    },
    isComposeEmailOpen: true,
    openAddComment: () => {
      //
    },
    closeAddComment: () => {
      //
    },
    toggleAddComment: () => {
      //
    },
    isAddCommentOpen: true
  });

export function ViewJobCandidateDetailsProvider({
  children
}: ViewJobCandidateDetailsProviderProps) {
  const router = useRouter();

  const {
    onOpen: openProfileOverview,
    onClose: closeProfileOverview,
    onToggle: toggleProfileOverview,
    isOpen: isProfileOverviewOpen
  } = useDisclosure({
    defaultIsOpen: true
  });

  const {
    onOpen: openComposeEmail,
    onClose: closeComposeEmail,
    onToggle: toggleComposeEmail,
    isOpen: isComposeEmailOpen
  } = useDisclosure();

  const {
    onOpen: openAddComment,
    onClose: closeAddComment,
    onToggle: toggleAddComment,
    isOpen: isAddCommentOpen
  } = useDisclosure();

  useEffect(() => {
    const handleRouteChange = () => {
      openProfileOverview();
    };

    router.events.on('routeChangeComplete', handleRouteChange);

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [openProfileOverview, router.events]);

  const values: ViewJobCandidateDetailsContextType = useMemo(
    () => ({
      openProfileOverview,
      closeProfileOverview,
      toggleProfileOverview,
      isProfileOverviewOpen,
      openComposeEmail,
      closeComposeEmail,
      toggleComposeEmail,
      isComposeEmailOpen,
      openAddComment,
      closeAddComment,
      toggleAddComment,
      isAddCommentOpen
    }),
    [
      openProfileOverview,
      closeProfileOverview,
      toggleProfileOverview,
      isProfileOverviewOpen,
      openComposeEmail,
      closeComposeEmail,
      toggleComposeEmail,
      isComposeEmailOpen,
      openAddComment,
      closeAddComment,
      toggleAddComment,
      isAddCommentOpen
    ]
  );

  return (
    <ViewJobCandidateDetailsContext.Provider value={values}>
      {children}
    </ViewJobCandidateDetailsContext.Provider>
  );
}

export const useViewJobCandidateDetailsContext = () =>
  useContext(ViewJobCandidateDetailsContext);
