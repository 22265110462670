import {
  ColorProps,
  Flex,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Stack,
  useDisclosure
} from '@chakra-ui/react';
import { Text } from '../../../../../../../../../../helpers/Text';
import { CalendarIcon } from '../../../../../../../../../../icons/CalendarIcon';
import { EmailIcon } from '../../../../../../../../../../icons/EmailIcon';
import { JobsIcon } from '../../../../../../../../../../icons/JobsIcon';
import { PhoneIcon } from '../../../../../../../../../../icons/PhoneIcon';
import { ViewJobCandidateDetailsProfileCalendarActivityPopover } from '../ViewJobCandidateDetailsProfileCalendarActivityPopover';

export type ViewJobCandidateDetailsProfileCalendarActivityType = {
  id: string;
  due: string;
  title: string;
  reminder: string;
  comment?: string;
  description?: string;
  type?: 'email' | 'phone' | 'calendar' | 'offer';
  status: 'in-progress' | 'not-started';
  candidateName: string;
  jobTitle: string;
};

type ViewJobCandidateDetailsProfileCalendarActivityItemProps = {
  event: ViewJobCandidateDetailsProfileCalendarActivityType;
};

export default function ViewJobCandidateDetailsProfileCalendarActivityItem({
  event
}: ViewJobCandidateDetailsProfileCalendarActivityItemProps) {
  const {
    isOpen: isOpenPopover,
    onToggle: onTogglePopover,
    onClose: onClosePopover
  } = useDisclosure();

  const eventTypeColor = (): ColorProps['color'] => {
    switch (event.type) {
      case 'calendar':
        return 'teal.500';
      case 'email':
        return 'purple.500';
      case 'offer':
        return 'yellow.500';
      case 'phone':
        return 'blue.500';
      default:
        return 'green.500';
    }
  };

  const eventTypeIcon = (): JSX.Element => {
    switch (event.type) {
      case 'calendar':
        return <CalendarIcon w={4} h={4} fill={eventTypeColor()} />;
      case 'email':
        return <EmailIcon w={4} h={4} fill={eventTypeColor()} />;
      case 'offer':
        return <JobsIcon w={4} h={4} fill={eventTypeColor()} />;
      case 'phone':
        return <PhoneIcon w={3.5} h={3.5} fill={eventTypeColor()} />;
      default:
        return <></>;
    }
  };

  return (
    <Popover
      isLazy
      arrowSize={20}
      placement="right"
      returnFocusOnClose={false}
      onClose={onClosePopover}
      isOpen={isOpenPopover}
      offset={[0, 14]}
    >
      <PopoverTrigger>
        <Stack
          as="button"
          role="group"
          pos="relative"
          display="flex"
          textAlign="left"
          border="1px solid"
          borderRadius="base"
          borderColor="gray.200"
          transitionDuration="slow"
          onClick={onTogglePopover}
          borderLeftColor="transparent"
          borderLeftWidth={4}
          _hover={{
            bg: 'gray.50',
            borderColor: eventTypeColor()
          }}
          _before={{
            top: '-1px',
            left: '-4px',
            content: "''",
            pos: 'absolute',
            bg: eventTypeColor(),
            h: 'calc(100% + 2px)',
            borderRadius: '4px 0 0 4px',
            w: 1
          }}
        >
          <Stack
            px={3}
            py={2.5}
            spacing={1}
            display="flex"
            pos="relative"
            overflow="hidden"
          >
            <Flex gap={1.5} flexDir="row" alignItems="center">
              {eventTypeIcon()}

              <Text noOfLines={1} textStyle="body1Medium">
                {event.title}
              </Text>
            </Flex>

            <Text color="gray.600" textStyle="body2Regular">
              Due: {event.due}
            </Text>
          </Stack>
        </Stack>
      </PopoverTrigger>

      <Portal>
        <PopoverContent
          w={96}
          boxShadow="10px 10px 25px -5px rgba(0, 0, 0, 0.1), 25px 0 25px 25px rgba(0, 0, 0, 0.04)"
        >
          <PopoverArrow />

          <PopoverBody p={4}>
            <ViewJobCandidateDetailsProfileCalendarActivityPopover
              event={event}
              icon={eventTypeIcon()}
              color={eventTypeColor()}
              togglePopover={onTogglePopover}
            />
          </PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>
  );
}
