import { Box, Collapse, Flex, useDisclosure } from '@chakra-ui/react';
import { Text } from '../../../../../../../../helpers/Text';
import { getColorShade } from '../../../../../../../../utils/scorecard/scorecard';

export interface ViewJobCandidateDetailsProfileOverviewJobFitScorecardSubField {
  label: string;
  value: number;
  totalObtainable: number;
  color: string;
}

export interface ViewJobCandidateDetailsProfileOverviewJobFitScorecardField {
  label: string;
  value: number;
  totalObtainable: number;
  color: string;
  percentage?: number;
  subfields: ViewJobCandidateDetailsProfileOverviewJobFitScorecardSubField[];
}

interface ViewJobCandidateDetailsProfileOverviewJobFitScorecardItemProps {
  field: ViewJobCandidateDetailsProfileOverviewJobFitScorecardField;
}

function ViewJobCandidateDetailsProfileOverviewJobFitScorecardItem({
  field
}: ViewJobCandidateDetailsProfileOverviewJobFitScorecardItemProps) {
  const {
    isOpen: isHovered,
    onOpen: onMouseHover,
    onClose: onMouseLeave
  } = useDisclosure();

  const { label, value, totalObtainable, color, subfields } = field;

  const percentage =
    field.percentage ||
    (totalObtainable > 0 ? Math.min(100, (value / totalObtainable) * 100) : 0);

  return (
    <Flex
      flexDir="column"
      onMouseOver={onMouseHover}
      onMouseLeave={onMouseLeave}
    >
      <Box>
        <Text textStyle="body1Regular" textTransform="capitalize">
          {label}
        </Text>

        <Flex gap={2} alignItems="center">
          <Flex h={2} flex={1} bg="gray.100" rounded="sm">
            <Flex
              h={2}
              bg={getColorShade(percentage, color)}
              w={`${percentage}%`}
              rounded="sm"
            />
          </Flex>

          <Text w={12} textAlign="right" textStyle="body2Medium">
            {value}/{totalObtainable}
          </Text>
        </Flex>
      </Box>

      <Collapse in={isHovered}>
        <Flex flexDir="column" ml={2} mt={1}>
          {subfields.map((item) => {
            const percentage =
              item.totalObtainable > 0
                ? Math.min(100, (item.value / item.totalObtainable) * 100)
                : 0;

            return (
              <Flex gap={2} alignItems="center" key={item.label} my={1}>
                <Text
                  w={24}
                  noOfLines={1}
                  textAlign="right"
                  textStyle="body1Regular"
                  fontSize="sm"
                  title={item.label}
                >
                  {item.label}
                </Text>

                <Flex h={2} flex={1} bg="gray.100" rounded="sm">
                  <Flex
                    h={2}
                    bg={getColorShade(percentage, item.color)}
                    w={`${percentage}%`}
                    rounded="sm"
                  />
                </Flex>

                <Text
                  w={12}
                  textAlign="right"
                  textStyle="body2Medium"
                  fontSize="sm"
                >
                  {item.value}/{item.totalObtainable}
                </Text>
              </Flex>
            );
          })}
        </Flex>
      </Collapse>
    </Flex>
  );
}

export default ViewJobCandidateDetailsProfileOverviewJobFitScorecardItem;
