import {
  MayBe,
  FieldBrand,
  ItemID,
  ItemIDs,
  ItemUUID,
  ItemNanoId,
  ItemCreatedAt
} from '../../../types';

import { JobId } from '../../jobs/jobsTypes';

import { JobCandidateId } from '../../jobCandidates/jobCandidatesTypes';

import { JobResumeId } from '../../jobResumes/jobResumesTypes';

import { JobResumeRecruiterId } from '../../jobResumeRecruiters/jobResumeRecruitersTypes';

import {
  SkillId,
  SkillNanoId,
  SkillName,
  SkillKind,
  SkillKindValue
} from '../../skills/skillsTypes';

import {
  SkillLicenseId,
  SkillLicenseNanoId,
  SkillLicenseName
} from '../../skillLicenses/skillLicensesTypes';

import { TalentPoolId } from '../../talentPools/talentPoolsTypes';

import { UserId } from '../../users/usersTypes';
import {
  EmploymentId,
  EmploymentJobTitle,
  EmploymentName,
  MayBeEmploymentCompany
} from '../../employments/employmentsTypes';
import {
  EmploymentSkillBadgeEmployment,
  EmploymentSkillBadgeEmploymentId,
  EmploymentSkillBadgeId,
  EmploymentSkillBadgeUsage
} from '../../employmentSkillBadges/employmentSkillBadgesTypes';
import { ContactId } from '../../contacts/contactsTypes';

export type SkillBadgeRatingType =
  | 'no_experience'
  | 'beginner'
  | 'intermediate'
  | 'professional'
  | 'expert';

export type SkillBadgeId = FieldBrand<ItemID, 'SkillBadgeId'>;
export type SkillBadgeIds = FieldBrand<ItemIDs, 'SkillBadgeIds'>;
export type SkillBadgeUUID = FieldBrand<ItemUUID, 'SkillBadgeUUID'>;
export type MayBeSkillBadgeUuid = MayBe<SkillBadgeUUID>;
export type SkillBadgeNanoId = FieldBrand<ItemNanoId, 'SkillBadgeNanoId'>;
export type MayBeSkillBadgeNanoId = MayBe<SkillBadgeNanoId>;
export type SkillBadgeRating = FieldBrand<
  SkillBadgeRatingType,
  'SkillBadgeRating'
>;
export type SkillBadgeExperience = FieldBrand<string, 'SkillBadgeExperience'>;
export type SkillBadgeExperienceType = FieldBrand<
  string,
  'SkillBadgeExperienceType'
>;
export type SkillBadgeCertified = FieldBrand<boolean, 'SkillBadgeCertified'>;
export type SkillBadgeRequirements = FieldBrand<
  SkillBadgeRequirementsTypes,
  'SkillBadgeRequirements'
>;
export type SkillBadgeSkillId = FieldBrand<SkillId, 'SkillBadgeSkillId'>;
export type SkillBadgeSkill = FieldBrand<
  {
    // id: SkillId;
    nanoId: SkillNanoId;
    name: SkillName;
    kind: SkillKind | SkillKindValue;
  },
  'SkillBadgeSkill'
>;
export type MayBeSkillBadgeSkillId = MayBe<SkillBadgeSkillId>;
export type MayBeSkillBadgeSkill = MayBe<SkillBadgeSkill>;
export type SkillBadgeSkillLicenseId = FieldBrand<
  SkillLicenseId,
  'SkillBadgeSkillLicenseId'
>;
export type MayBeSkillBadgeSkillLicenseId = MayBe<SkillBadgeSkillLicenseId>;
export type SkillBadgeSkillLicense = FieldBrand<
  {
    // id: SkillLicenseId;
    nanoId: SkillLicenseNanoId;
    name: SkillLicenseName;
  },
  'SkillBadgeSkillLicense'
>;
export type MayBeSkillBadgeSkillLicense = MayBe<SkillBadgeSkillLicense>;
export type SkillBadgeJobId = FieldBrand<JobId, 'SkillBadgeJobId'>;
export type MayBeSkillBadgeJobId = MayBe<SkillBadgeJobId>;
export type SkillBadgeJobCandidateId = FieldBrand<
  JobCandidateId,
  'SkillBadgeJobCandidateId'
>;
export type MayBeSkillBadgeJobCandidateId = MayBe<SkillBadgeJobCandidateId>;
export type SkillBadgeJobResumeId = FieldBrand<
  JobResumeId,
  'SkillBadgeJobResumeId'
>;
export type MayBeSkillBadgeJobResumeId = MayBe<SkillBadgeJobResumeId>;

export type SkillBadgeJobResumeRecruiterId = FieldBrand<
  JobResumeRecruiterId,
  'SkillBadgeJobResumeRecruiterId'
>;

export type SkillBadgeTalentPoolId = FieldBrand<
  TalentPoolId,
  'SkillBadgeTalentPoolId'
>;
export type SkillBadgeProfileId = FieldBrand<UserId, 'SkillBadgeProfileId'>;
export type SkillBadgeContactId = FieldBrand<ContactId, 'SkillBadgeContactId'>;

export type SkillBadgeUserId = FieldBrand<UserId, 'SkillBadgeUserId'>;
export type SkillBadgeCreatedAt = FieldBrand<
  ItemCreatedAt,
  'SkillBadgeCreatedAt'
>;

export type SkillBadgeEmploymentIds = FieldBrand<
  EmploymentId[],
  'SkillBadgeEmploymentIds'
>;

export type MayBeSkillBadgeEmploymentIds = MayBe<SkillBadgeEmploymentIds>;

export type SkillBadgeEmployments = FieldBrand<
  {
    id: EmploymentId;
    name: EmploymentName;
    jobTitle: EmploymentJobTitle;
    company: MayBeEmploymentCompany;
  }[],
  'SkillBadgeEmployments'
>;

export type MayBeSkillBadgeEmployments = MayBe<SkillBadgeEmployments>;

export type SkillBadgeEmploymentSkillBadgeIds = FieldBrand<
  EmploymentSkillBadgeId[],
  'SkillBadgeEmploymentSkillBadgeIds'
>;

export type MayBeSkillBadgeEmploymentSkillBadgeIds =
  MayBe<SkillBadgeEmploymentSkillBadgeIds>;

export type SkillBadgeEmploymentSkillBadges = FieldBrand<
  {
    id: EmploymentSkillBadgeId;
    usage: EmploymentSkillBadgeUsage;
    employmendId: EmploymentSkillBadgeEmploymentId;
    employment: EmploymentSkillBadgeEmployment;
  }[],
  'SkillBadgeEmploymentSkillBadges'
>;

export type MayBeSkillBadgeEmploymentSkillBadges =
  MayBe<SkillBadgeEmploymentSkillBadges>;

export const enum SkillBadgeRequirementsTypes {
  NONE = 'none',
  REQUIRED = 'required',
  IMPORTANT = 'important',
  NICE_TO_HAVE = 'nice_to_have',
  NOT_REQUIRED = 'not_required'
}

export const enum SkillBadgeExperienceTypes {
  CURRENT_JOB = 'current_job',
  PAST_JOB = 'past_job',
  ALL_JOBS = 'all_jobs'
}

export const skillBadgeRequirements: SkillBadgeRequirements[] = [
  SkillBadgeRequirementsTypes.REQUIRED as SkillBadgeRequirements,
  SkillBadgeRequirementsTypes.IMPORTANT as SkillBadgeRequirements,
  SkillBadgeRequirementsTypes.NICE_TO_HAVE as SkillBadgeRequirements
  // SkillBadgeRequirementsTypes.NOT_REQUIRED as SkillBadgeRequirements
];

export const skillBadgeExperienceTypes: SkillBadgeExperienceType[] = [
  SkillBadgeExperienceTypes.CURRENT_JOB as SkillBadgeExperienceType,
  SkillBadgeExperienceTypes.PAST_JOB as SkillBadgeExperienceType,
  SkillBadgeExperienceTypes.ALL_JOBS as SkillBadgeExperienceType
];

export const enum SkillBadgeFields {
  ID = 'id',
  UUID = 'uuid',
  NANO_ID = 'nanoId',
  RATING = 'rating',
  EXPERIENCE = 'experience',
  EXPERIENCE_TYPE = 'experienceType',
  CERTIFIED = 'certified',
  REQUIREMENTS = 'requirements',
  USER_ID = 'userId',
  JOB_ID = 'jobId',
  JOB_CANDIDATE_ID = 'jobCandidateId',
  JOB_RESUME_ID = 'jobResumeId',
  JOB_RESUME_RECRUITER_ID = 'jobResumeRecruiterId',
  TALENT_POOL_ID = 'talentPoolId',
  PROFILE_ID = 'profileId',
  CONTACT_ID = 'contactId',
  SKILL_ID = 'skillId',
  SKILL = 'skill',
  SKILL_LICENSE_ID = 'skillLicenseId',
  SKILL_LICENSE = 'skillLicense',
  CREATED_AT = 'createdAt',
  UPDATED_AT = 'updatedAt',
  EMPLOYMENT_IDS = 'employmentIds',
  EMPLOYMENTS = 'employments',
  EMPLOYMENT_SKILL_BADGE_IDS = 'employmentSkillBadgeIds',
  EMPLOYMENT_SKILL_BADGES = 'employmentSkillBadges'
}
