import { Box, ButtonGroup, Flex, HStack, Stack } from '@chakra-ui/react';
import { Button } from '../../../../../../helpers/Button';
import { Text } from '../../../../../../helpers/Text';
import { WysiwygFieldControl } from '../../../../../../helpers/forms/formFields/WysiwygField/components/WysiwygFieldControl';
import { ChevronDownIcon } from '../../../../../../icons/ChevronDownIcon';

interface ViewJobCandidateDetailsAddCommentProps {
  addComment?: () => void;
  onDiscard: () => void;
}

function ViewJobCandidateDetailsAddComment({
  addComment,
  onDiscard
}: ViewJobCandidateDetailsAddCommentProps) {
  return (
    <Stack
      sx={{
        '.rdw-fontsize-wrapper, .rdw-image-wrapper': {
          display: 'none !important'
        },
        '.rdw-fontfamily-wrapper': {
          display: 'none !important'
        }
      }}
    >
      <Stack p={4} bg="white" borderRadius="base" spacing={4}>
        <Stack spacing={4}>
          <Flex gap={4} flexDir="column" flex={1}>
            <Flex gap={4} alignItems="center" w="full">
              <Text fontSize="md" fontWeight="semibold">
                Add a comment
              </Text>

              <Button
                ml="auto"
                size="small"
                color="gray.700"
                boxShadow="none"
                hierarchy="ghost"
                rightIcon={<ChevronDownIcon w={4} h={4} />}
              >
                Select Template
              </Button>
            </Flex>

            <Box h="220px">
              <WysiwygFieldControl
                name="Test"
                value=""
                defaultValue=""
                onChange={() => {
                  //
                }}
              />
            </Box>
          </Flex>
        </Stack>

        <HStack flex={1} w="full">
          <ButtonGroup ml="auto" spacing={2}>
            <Button hierarchy="tertiary" size="small" onClick={onDiscard}>
              Cancel
            </Button>

            <Button size="small" onClick={addComment}>
              Add comment
            </Button>
          </ButtonGroup>
        </HStack>
      </Stack>
    </Stack>
  );
}

export default ViewJobCandidateDetailsAddComment;
