import { JobId } from '../jobs/jobsTypes';

import { JobCandidateId } from '../jobCandidates/jobCandidatesTypes';

import {
  CreateJobCandidateAssessmentError,
  FetchJobCandidateAssessmentIndexQueryName,
  FetchJobCandidateAssessmentQuery,
  FetchJobCandidateAssessmentsCountType,
  FetchJobCandidateAssessmentsFilters,
  FetchJobCandidateAssessmentsIndexCountType,
  FetchJobCandidateAssessmentsIndexFilters,
  FetchJobCandidateAssessmentsIndexPage,
  FetchJobCandidateAssessmentsIndexPageSize,
  FetchJobCandidateAssessmentsIndexQueryName,
  FetchJobCandidateAssessmentsIndexSort,
  FetchJobCandidateAssessmentsPage,
  FetchJobCandidateAssessmentsPageSize,
  FetchJobCandidateAssessmentsQuery,
  FetchJobCandidateAssessmentsSort,
  JobCandidateAssessmentId,
  JobCandidateAssessmentNanoId,
  JobCandidateAssessmentSettings,
  JobCandidateAssessmentStatus,
  UpdateJobCandidateAssessmentError,
  CancelJobCandidateAssessmentError
} from './jobCandidateAssessmentsTypes';

import { BaseBffRequests } from '../BaseBffRequests';

import { JobCandidateAssessmentsBffRoutes } from './JobCandidateAssessmentsBffRoutes';

interface FetchJobCandidateAssessmentsRequestParams {
  query: FetchJobCandidateAssessmentsQuery;
  countType?: FetchJobCandidateAssessmentsCountType;
  page?: FetchJobCandidateAssessmentsPage;
  pageSize?: FetchJobCandidateAssessmentsPageSize;
  filters?: FetchJobCandidateAssessmentsFilters;
  sort?: FetchJobCandidateAssessmentsSort;
}

interface FetchJobCandidateAssessmentsIndexRequestParams {
  queryName: FetchJobCandidateAssessmentsIndexQueryName;
  countType?: FetchJobCandidateAssessmentsIndexCountType;
  page?: FetchJobCandidateAssessmentsIndexPage;
  pageSize?: FetchJobCandidateAssessmentsIndexPageSize;
  filters?: FetchJobCandidateAssessmentsIndexFilters;
  sort?: FetchJobCandidateAssessmentsIndexSort;
}

interface FetchJobCandidateAssessmentRequestParams {
  query: FetchJobCandidateAssessmentQuery;
}

interface FetchJobCandidateAssessmentIndexRequestParams {
  queryName: FetchJobCandidateAssessmentIndexQueryName;
}

export interface JobCandidateAssessmentRequestData {
  settings: JobCandidateAssessmentSettings;
  jobId: JobId;
  jobCandidateId: JobCandidateId;
}

export interface CreateJobCandidateAssessmentRequestData
  extends JobCandidateAssessmentRequestData {
  id?: JobCandidateAssessmentId;
}

export interface UpdateJobCandidateAssessmentRequestData {
  status: JobCandidateAssessmentStatus;
}

export interface CreateJobCandidateAssessmentErrorResponse {
  message: CreateJobCandidateAssessmentError;
}

export interface UpdateJobCandidateAssessmentErrorResponse {
  message: UpdateJobCandidateAssessmentError;
}

export interface CancelJobCandidateAssessmentErrorResponse {
  message: CancelJobCandidateAssessmentError;
}

export class JobCandidateAssessmentsBffRequests extends BaseBffRequests {
  static fetchJobCandidateAssessments<T>(
    params: FetchJobCandidateAssessmentsRequestParams
  ) {
    return this.fetchItems<T>(
      JobCandidateAssessmentsBffRoutes.fetchJobCandidateAssessmentsRoute(),
      params
    );
  }

  static fetchJobCandidateAssessmentsIndex<T>(
    params: FetchJobCandidateAssessmentsIndexRequestParams
  ) {
    return this.fetchItemsIndex<T>(
      JobCandidateAssessmentsBffRoutes.fetchJobCandidateAssessmentsIndexRoute(),
      params
    );
  }

  static fetchJobCandidateAssessment<T>(
    jobCandidateAssessmentId: JobCandidateAssessmentId,
    params: FetchJobCandidateAssessmentRequestParams
  ) {
    return this.fetchItem<T>(
      JobCandidateAssessmentsBffRoutes.fetchJobCandidateAssessmentRoute(
        jobCandidateAssessmentId
      ),
      params
    );
  }

  static fetchJobCandidateAssessmentIndex<T>(
    jobCandidateAssessmentNanoId: JobCandidateAssessmentNanoId,
    params: FetchJobCandidateAssessmentIndexRequestParams
  ) {
    return this.fetchItemIndex<T>(
      JobCandidateAssessmentsBffRoutes.fetchJobCandidateAssessmentIndexRoute(
        jobCandidateAssessmentNanoId
      ),
      params
    );
  }

  static fetchJobCandidateAssessmentByNanoId<T>(
    jobCandidateAssessmentNanoId: JobCandidateAssessmentNanoId,
    params: FetchJobCandidateAssessmentRequestParams
  ) {
    return this.fetchItemByNanoId<T>(
      JobCandidateAssessmentsBffRoutes.fetchJobCandidateAssessmentByNanoIdRoute(
        jobCandidateAssessmentNanoId
      ),
      params
    );
  }

  static createJobCandidateAssessment<T>(
    createJobCandidateAssessmentData: CreateJobCandidateAssessmentRequestData
  ) {
    return this.createItem<T, CreateJobCandidateAssessmentRequestData>(
      JobCandidateAssessmentsBffRoutes.createJobCandidateAssessmentRoute(),
      createJobCandidateAssessmentData
    );
  }

  static async updateJobCandidateAssessment<T>(
    jobCandidateAssessmentNanoId:
      | JobCandidateAssessmentNanoId
      | JobCandidateAssessmentId,
    updateJobCandidateAssessmentData: UpdateJobCandidateAssessmentRequestData
  ) {
    return this.updateItem<T, UpdateJobCandidateAssessmentRequestData>(
      JobCandidateAssessmentsBffRoutes.updateJobCandidateAssessmentRoute(
        jobCandidateAssessmentNanoId
      ),
      updateJobCandidateAssessmentData
    );
  }

  static async cancelJobCandidateAssessment<T>(
    jobCandidateAssessmentNanoId: JobCandidateAssessmentNanoId
  ) {
    return this.postItem<T, Record<string, unknown>>(
      JobCandidateAssessmentsBffRoutes.cancelJobCandidateAssessmentRoute(
        jobCandidateAssessmentNanoId
      ),
      { jobCandidateAssessmentId: jobCandidateAssessmentNanoId }
    );
  }

  static async resendJobCandidateAssessment<T>(
    jobCandidateAssessmentNanoId: JobCandidateAssessmentNanoId
  ) {
    return this.postItem<T, Record<string, unknown>>(
      JobCandidateAssessmentsBffRoutes.resendJobCandidateAssessmentRoute(
        jobCandidateAssessmentNanoId
      ),
      { jobCandidateAssessmentId: jobCandidateAssessmentNanoId }
    );
  }
}
