import { useQuery, useQueryClient } from 'react-query';
import {
  notificationToastCacheKey,
  NotificationItem
} from './useNotificationToast.types';
import { DateUtils, DateTimeFormats } from '../../../../utils/DateUtils';
import { generateUuid } from '../../../../utils/generateUuid';

function useNotificationToast() {
  const queryClient = useQueryClient();

  const { data: notifications = [] } = useQuery<NotificationItem[]>({
    queryKey: [notificationToastCacheKey],
    initialData: []
  });

  const addToast = (notification: NotificationItem) => {
    const baseTime = new Date();
    baseTime.setHours(9, 0, 0, 0);

    const newTime = new Date(baseTime.getTime());

    for (let i = 0; i < notifications.length; i++) {
      const interval = Math.random() < 0.5 ? 30 : 60;
      newTime.setMinutes(newTime.getMinutes() + interval);
      if (newTime.getHours() >= 17) {
        newTime.setHours(17, 0, 0, 0);
        break;
      }
    }

    const formattedTime = newTime.toLocaleTimeString([], {
      hour: '2-digit',
      minute: '2-digit',
      hour12: true
    });

    const newNotification: NotificationItem = {
      id: generateUuid(),
      name: notification.name,
      title: notification.title || `Reminder ${notifications.length + 1}`,
      description: notification.description,
      date: `Due: ${
        notification.date
          ? DateUtils.formatDate(notification.date, DateTimeFormats.DD_MMM_YYYY)
          : formattedTime
      }`
    };

    queryClient.setQueryData(notificationToastCacheKey, [
      ...notifications,
      newNotification
    ]);
  };

  const removeToast = (id: string) => {
    queryClient.setQueryData(
      notificationToastCacheKey,
      notifications.filter((n) => n.id !== id)
    );
  };

  const clearAllToasts = () => {
    queryClient.setQueryData(notificationToastCacheKey, []);
  };

  return {
    notifications,
    addToast,
    removeToast,
    clearAllToasts
  };
}

export default useNotificationToast;
