import {
  Avatar,
  Flex,
  Menu,
  MenuButton,
  MenuGroup,
  MenuItem,
  MenuList,
  useDisclosure
} from '@chakra-ui/react';
import { AnimatePresence, motion } from 'framer-motion';
import { NotificationItem } from '../../../../common/hooks/utils/useNotificationToast/useNotificationToast.types';
import { AlarmIcon } from '../../../../icons/AlarmIcon';
import { CancelIcon } from '../../../../icons/CancelIcon';
import { Text } from '../../../Text';
import { IconButton } from '../../../buttons/IconButton';

export interface NotificationToastItemProps {
  removeNotification: (id: string) => void;
  notification: NotificationItem;
}

export default function NotificationToastItem({
  notification,
  removeNotification
}: NotificationToastItemProps) {
  const {
    isOpen: isHovered,
    onOpen: onMouseHover,
    onClose: onMouseLeave
  } = useDisclosure();

  return (
    <AnimatePresence>
      <motion.div
        key={notification.id}
        transition={{ duration: 0.3 }}
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: -20 }}
      >
        <Flex
          p={3}
          pr={0}
          gap={3}
          bg="white"
          alignItems="center"
          position="relative"
          onMouseOver={onMouseHover}
          onMouseLeave={onMouseLeave}
          _hover={{
            bg: 'gray.100'
          }}
        >
          <Avatar size="sm" name={notification.name || notification.title} />

          <Flex flexDir="column" flex={1}>
            <Text textStyle="body1Medium">{notification.title}</Text>

            {notification.date ? (
              <Text
                textStyle="body2Regular"
                lineHeight="short"
                color="gray.600"
              >
                {notification.date}
              </Text>
            ) : (
              <>
                {notification.description && (
                  <Text
                    textStyle="body2Regular"
                    lineHeight="short"
                    color="gray.600"
                  >
                    {notification.description}
                  </Text>
                )}
              </>
            )}
          </Flex>

          <Flex gap={1} alignItems="center">
            {isHovered && (
              <Flex>
                <Menu isLazy placement="left">
                  <MenuButton
                    size="small"
                    boxShadow="none"
                    hierarchy="ghost"
                    aria-label="Snooze"
                    icon={<AlarmIcon />}
                    as={IconButton}
                  />
                  <MenuList>
                    <MenuGroup title="Snooze">
                      <MenuItem>5 minutes</MenuItem>
                      <MenuItem>10 minutes</MenuItem>
                      <MenuItem>15 minutes</MenuItem>
                    </MenuGroup>
                  </MenuList>
                </Menu>
              </Flex>
            )}

            <Flex w={8} overflow="hidden">
              {isHovered && (
                <motion.div
                  key={notification.id}
                  transition={{ duration: 0.2 }}
                  initial={{ opacity: 0, x: 20 }}
                  animate={{ opacity: 1, x: 0 }}
                  exit={{ opacity: 0, x: -20 }}
                >
                  <IconButton
                    ml="auto"
                    size="small"
                    boxShadow="none"
                    hierarchy="ghost"
                    aria-label="Dismiss"
                    onClick={() => removeNotification(notification.id)}
                    icon={<CancelIcon w={5} h={5} />}
                  />
                </motion.div>
              )}
            </Flex>
          </Flex>
        </Flex>
      </motion.div>
    </AnimatePresence>
  );
}
