import { Flex, FlexboxProps, Grid, GridItem } from '@chakra-ui/react';
import { ReactNode } from 'react';

interface ViewJobCandidateDetailsProfileCalendarItemGridProps {
  icon?: ReactNode;
  children: ReactNode;
  iconAlignment?: FlexboxProps['alignItems'];
}
export default function ViewJobCandidateDetailsProfileCalendarItemGrid({
  icon,
  iconAlignment,
  children
}: ViewJobCandidateDetailsProfileCalendarItemGridProps) {
  return (
    <Grid
      gridTemplateColumns="32px 1fr"
      _first={{
        '>*': {
          pt: 0
        }
      }}
      _last={{
        '>*': {
          pb: 0,
          border: 'none'
        }
      }}
    >
      <GridItem py={2} display="flex" alignItems={iconAlignment}>
        <Flex
          w={6}
          h={6}
          alignItems="center"
          justifyContent="center"
          sx={{
            '>svg': {
              fill: 'gray.500'
            }
          }}
        >
          {icon}
        </Flex>
      </GridItem>

      <GridItem
        py={2}
        color="gray.700"
        borderBottom="1px solid"
        borderColor="gray.200"
      >
        {children}
      </GridItem>
    </Grid>
  );
}
