import snakeCase from 'lodash/snakeCase';

import {
  FetchSkillBadgesQuery,
  SkillBadgeFields,
  SkillBadgeId,
  SkillBadgeNanoId,
  SkillBadgeCertified,
  SkillBadgeExperience,
  SkillBadgeRequirements,
  SkillBadgeRating,
  SkillBadgeJobId,
  SkillBadgeSkill,
  SkillBadgeSkillLicenseId,
  SkillBadgeSkillLicense,
  SkillBadgeCreatedAt,
  SkillBadgeJobCandidateId,
  SkillBadgeJobResumeId,
  SkillBadgeSkillId,
  SkillBadgeExperienceType,
  SkillBadgeTalentPoolId,
  MayBeSkillBadgeEmploymentIds,
  MayBeSkillBadgeEmploymentSkillBadgeIds,
  MayBeSkillBadgeEmploymentSkillBadges,
  MayBeSkillBadgeEmployments,
  SkillBadgeContactId
} from '../skillBadgesTypes';

export interface FetchSkillBadgesResponse {
  [SkillBadgeFields.ID]: SkillBadgeId;
  [SkillBadgeFields.NANO_ID]: SkillBadgeNanoId;
  [SkillBadgeFields.RATING]: SkillBadgeRating;
  [SkillBadgeFields.EXPERIENCE]: SkillBadgeExperience;
  [SkillBadgeFields.EXPERIENCE_TYPE]: SkillBadgeExperienceType;
  [SkillBadgeFields.CERTIFIED]: SkillBadgeCertified;
  [SkillBadgeFields.REQUIREMENTS]: SkillBadgeRequirements;
  [SkillBadgeFields.SKILL_LICENSE_ID]: SkillBadgeSkillLicenseId;
  [SkillBadgeFields.SKILL_LICENSE]: SkillBadgeSkillLicense;
  [SkillBadgeFields.CONTACT_ID]: SkillBadgeContactId;
  [SkillBadgeFields.JOB_ID]: SkillBadgeJobId;
  [SkillBadgeFields.JOB_CANDIDATE_ID]: SkillBadgeJobCandidateId;
  [SkillBadgeFields.JOB_RESUME_ID]: SkillBadgeJobResumeId;
  [SkillBadgeFields.TALENT_POOL_ID]: SkillBadgeTalentPoolId;
  [SkillBadgeFields.SKILL_ID]: SkillBadgeSkillId;
  [SkillBadgeFields.SKILL]: SkillBadgeSkill;
  [SkillBadgeFields.CREATED_AT]: SkillBadgeCreatedAt;
  [SkillBadgeFields.UPDATED_AT]: SkillBadgeCreatedAt;
  [SkillBadgeFields.EMPLOYMENT_IDS]: MayBeSkillBadgeEmploymentIds;
  [SkillBadgeFields.EMPLOYMENTS]: MayBeSkillBadgeEmployments;
  [SkillBadgeFields.EMPLOYMENT_SKILL_BADGE_IDS]: MayBeSkillBadgeEmploymentSkillBadgeIds;
  [SkillBadgeFields.EMPLOYMENT_SKILL_BADGES]: MayBeSkillBadgeEmploymentSkillBadges;
}

export const fetchSkillBadgesColumns = [
  SkillBadgeFields.ID,
  SkillBadgeFields.NANO_ID,
  SkillBadgeFields.RATING,
  SkillBadgeFields.EXPERIENCE,
  SkillBadgeFields.CERTIFIED,
  SkillBadgeFields.REQUIREMENTS,
  SkillBadgeFields.SKILL_LICENSE_ID,
  SkillBadgeFields.JOB_ID,
  SkillBadgeFields.JOB_CANDIDATE_ID,
  SkillBadgeFields.JOB_RESUME_ID,
  SkillBadgeFields.TALENT_POOL_ID,
  SkillBadgeFields.SKILL_ID,
  SkillBadgeFields.CREATED_AT
];

const fetchSkillBadgesSBColumns = fetchSkillBadgesColumns.map(
  (fetchSkillBadgesColumn) => snakeCase(fetchSkillBadgesColumn)
);

export const fetchSkillBadgesQuery = `
  ${fetchSkillBadgesSBColumns.join(',')},
  skill:skill_id(id,nano_id,name,kind),
  skills!inner(id,nano_id,name,kind),
  skill_license:skill_license_id(id,nano_id,name)
` as FetchSkillBadgesQuery;
