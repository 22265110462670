import { Box, Collapse, Flex, Stack } from '@chakra-ui/react';
import { useRouter } from 'next/router';
import { ReactNode, useEffect, useRef } from 'react';
import { PageHead } from '../../../../../../helpers/PageHead';
import { Text } from '../../../../../../helpers/Text';
import { IsDisabled } from '../../../../../../types';
import { DepartmentNanoId } from '../../../../../departments/departmentsTypes';
import { JobCandidateNanoId } from '../../../../../jobCandidates/jobCandidatesTypes';
import { WorkflowStagesCache } from '../../../../../workflowStages/WorkflowStagesCache';
import { usePaginatedWorkflowStages } from '../../../../../workflowStages/hooks/usePaginatedWorkflowStages';
import {
  FetchWorkflowStagesResponse,
  fetchWorkflowStagesQuery
} from '../../../../../workflowStages/queries/fetchWorkflowStages.query';
import {
  FetchWorkflowStagesFilters,
  FetchWorkflowStagesSort,
  WorkflowStageFields
} from '../../../../../workflowStages/workflowStagesTypes';
import { JobNanoId } from '../../../../jobsTypes';
import { useViewJobCandidateDetailsContext } from '../../contexts/ViewJobCandidateDetailsContext';
import {
  ViewJobCandidateDetailsCandidate,
  ViewJobCandidateDetailsJobType
} from '../../pages/ViewJobCandidateDetailsProfilePage/ViewJobCandidateDetailsProfilePage.types';
import { ViewJobCandidateDetailsAddComment } from '../ViewJobCandidateDetailsAddComment';
import { ViewJobCandidateDetailsComposeEmail } from '../ViewJobCandidateDetailsComposeEmail';
import ViewJobCandidateDetailsPageNav from '../ViewJobCandidateDetailsPageNav/ViewJobCandidateDetailsPageNav';
import { ViewJobCandidateDetailsProfileOverview } from '../ViewJobCandidateDetailsProfileOverview';

interface ViewJobCandidateDetailsPageTabsProps {
  prospect?: boolean;
  isBasePath?: boolean;
  children?: ReactNode;
  jobNanoId?: JobNanoId;
  isDisabled?: IsDisabled;
  shouldNotStick?: boolean;
  isJobDetailsPage?: boolean;
  isHiringPortalPath: boolean;
  departmentNanoId?: DepartmentNanoId;
  jobCandidateNanoId?: JobCandidateNanoId;
  jobCandidate: ViewJobCandidateDetailsCandidate;
  job: ViewJobCandidateDetailsJobType;
}

function ViewJobCandidateDetailsPageTabs({
  job,
  children,
  prospect,
  isDisabled,
  isBasePath,
  jobCandidate,
  shouldNotStick,
  departmentNanoId,
  isJobDetailsPage,
  isHiringPortalPath
}: ViewJobCandidateDetailsPageTabsProps) {
  const router = useRouter();
  const mainContentScrollRef = useRef<HTMLDivElement>(null);

  const {
    isAddCommentOpen,
    toggleAddComment,
    isComposeEmailOpen,
    toggleComposeEmail,
    isProfileOverviewOpen,
    toggleProfileOverview
  } = useViewJobCandidateDetailsContext();

  const { workflowStages } =
    usePaginatedWorkflowStages<FetchWorkflowStagesResponse>({
      query: fetchWorkflowStagesQuery,
      cacheKey: WorkflowStagesCache.jobIndexCacheKey(job.nanoId),
      initialFilters: {
        [WorkflowStageFields.JOB_ID]: { operator: 'eq', value: job.id }
      } as unknown as FetchWorkflowStagesFilters,
      initialSort: {
        [WorkflowStageFields.ARRANGEMENT]: { ascending: true }
      } as unknown as FetchWorkflowStagesSort
    });

  useEffect(() => {
    const shouldScroll = !isBasePath || isAddCommentOpen || isComposeEmailOpen;

    if (shouldScroll && mainContentScrollRef.current) {
      mainContentScrollRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start'
      });
    }
  }, [isBasePath, isAddCommentOpen, isComposeEmailOpen, router.pathname]);

  const isActivitiesPage = router.pathname.includes('activities');

  return (
    <>
      <PageHead i18nTitle={`${jobCandidate.name}'s profile`} />

      {isJobDetailsPage ? null : (
        <ViewJobCandidateDetailsPageNav
          jobCandidateNanoId={jobCandidate.nanoId}
          isHiringPortalPath={isHiringPortalPath}
          departmentNanoId={departmentNanoId}
          shouldNotStick={shouldNotStick}
          jobNanoId={job.nanoId}
          isDisabled={isDisabled}
          isBasePath={isBasePath}
          prospect={prospect}
        />
      )}

      <Flex gap={4} h="100%" pos="relative">
        {isJobDetailsPage ? null : (
          <ViewJobCandidateDetailsProfileOverview
            isActionOpen={isAddCommentOpen || isComposeEmailOpen}
            isHiringPortalPage={isHiringPortalPath}
            isActivitiesPage={isActivitiesPage}
            departmentNanoId={departmentNanoId}
            onToggle={toggleProfileOverview}
            workflowStages={workflowStages}
            isOpen={isProfileOverviewOpen}
            jobCandidate={jobCandidate}
            isDisabled={isDisabled}
            prospect={prospect}
            job={job}
          />
        )}

        <Stack flex={1} spacing={0} pos="relative">
          <Box
            h={6}
            zIndex={99}
            opacity={0}
            pos="absolute"
            pointerEvents="none"
            ref={mainContentScrollRef}
            top="-120px"
            w="full"
          />

          <Collapse in={isAddCommentOpen} animateOpacity unmountOnExit>
            <Stack mb={6} spacing={3}>
              <Text textStyle="body1Medium">New comment</Text>

              <Stack
                p={4}
                bg="gray.100"
                border="1px solid"
                borderColor="gray.200"
                borderRadius="base"
              >
                <ViewJobCandidateDetailsAddComment
                  onDiscard={toggleAddComment}
                  addComment={toggleAddComment}
                />
              </Stack>
            </Stack>
          </Collapse>

          <Collapse in={isComposeEmailOpen} animateOpacity unmountOnExit>
            <Stack mb={6} spacing={3}>
              <Text textStyle="body1Medium">New email</Text>

              <Stack
                p={4}
                bg="gray.100"
                border="1px solid"
                borderColor="gray.200"
                borderRadius="base"
              >
                <ViewJobCandidateDetailsComposeEmail
                  onSend={toggleComposeEmail}
                  onDiscard={toggleComposeEmail}
                />
              </Stack>
            </Stack>
          </Collapse>

          {children}
        </Stack>
      </Flex>
    </>
  );
}

export default ViewJobCandidateDetailsPageTabs;
