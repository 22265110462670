import {
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerOverlay,
  Flex,
  HStack,
  StackDivider,
  Tooltip,
  useDisclosure
} from '@chakra-ui/react';
import startCase from 'lodash/startCase';
import { useShareCandidatesProfileContext } from '../../../../../../context';
import { Button } from '../../../../../../helpers/Button';
import { IconButton } from '../../../../../../helpers/buttons/IconButton';
import { ButtonWithRef } from '../../../../../../helpers/ButtonWithRef';
import { NextLinkHelper } from '../../../../../../helpers/NextLinkHelper';
import { AddPersonIcon } from '../../../../../../icons/AddPersonIcon';
import { AlarmIcon } from '../../../../../../icons/AlarmIcon';
import { ChevronDownIcon } from '../../../../../../icons/ChevronDownIcon';
import { ChevronLeftIcon } from '../../../../../../icons/ChevronLeftIcon';
import { EmailIcon } from '../../../../../../icons/EmailIcon';
import { MessageIcon } from '../../../../../../icons/MessageIcon';
import { PhoneIcon } from '../../../../../../icons/PhoneIcon';
import { ShareIcon } from '../../../../../../icons/ShareIcon';
import { IsDisabled } from '../../../../../../types';
import { DepartmentNanoId } from '../../../../../departments/departmentsTypes';
import { HiringPortalJobCandidateDetailsRoutes } from '../../../../../hiringPortal/HiringPortalJobCandidateDetailsRoutes';
import { HiringPortalSourcingJobCandidateDetailsRoutes } from '../../../../../hiringPortal/HiringPortalSourcingJobCandidateDetailsRoutes';
import { JobCandidateNanoId } from '../../../../../jobCandidates/jobCandidatesTypes';
import { SourcingJobCandidateDetailsRoutes } from '../../../../../sourcing/SourcingJobCandidateDetailsRoutes';
import { ViewJobCandidateModalPhoneSidebarHelper } from '../../../../components/modals/ViewJobCandidateModal/helpers/ViewJobCandidateModalPhoneSidebarHelper';
import { JobNanoId } from '../../../../jobsTypes';
import { useViewJobCandidateDetailsContext } from '../../contexts/ViewJobCandidateDetailsContext';
import {
  JobCandidateDetailsJobCandidate,
  JobCandidateDetailsPageHandleGoBack
} from '../../ViewJobCandidateDetailsPage.types';
import { ViewJobCandidateDetailsRoutes } from '../../ViewJobCandidateDetailsRoutes';
import { ViewJobCandidateDetailsHeaderMenuAddJob } from '../ViewJobCandidateDetailsHeaderMenuAddJob';
import { ViewJobCandidateDetailsPageOverlay } from '../ViewJobCandidateDetailsPageOverlay';

interface ViewJobCandidateDetailsJobDetailsProps {
  prospect?: boolean;
  isDisabled?: IsDisabled;
  jobCandidate: JobCandidateDetailsJobCandidate;
  handleGoBack: JobCandidateDetailsPageHandleGoBack;
  handleRouteToJob: (jobNanoId: JobNanoId) => void;
  departmentNanoId?: DepartmentNanoId;
  isHiringPortalPath: boolean;
  jobNanoId: JobNanoId;
}

export default function ViewJobCandidateDetailsJobDetails({
  prospect,
  jobNanoId,
  isDisabled,
  jobCandidate,
  handleGoBack,
  handleRouteToJob,
  isHiringPortalPath,
  departmentNanoId = '' as DepartmentNanoId
}: ViewJobCandidateDetailsJobDetailsProps) {
  const { openAddComment, openComposeEmail } =
    useViewJobCandidateDetailsContext();

  const { shareJobCandidate } = useShareCandidatesProfileContext();

  const hiringPortalSourcingRoutes =
    isHiringPortalPath && prospect
      ? HiringPortalSourcingJobCandidateDetailsRoutes
      : null;

  const hiringPortalRoutes = isHiringPortalPath
    ? HiringPortalJobCandidateDetailsRoutes
    : null;

  const prospectRoute =
    hiringPortalSourcingRoutes || SourcingJobCandidateDetailsRoutes;

  const route = hiringPortalRoutes || ViewJobCandidateDetailsRoutes;

  const {
    isOpen: isOpenPhoneCallActivity,
    onOpen: onOpenPhoneCallActivity,
    onToggle: onTogglePhoneCallActivity
  } = useDisclosure();

  const assessmentUrl = prospect
    ? prospectRoute.assessment(departmentNanoId, jobCandidate.nanoId, jobNanoId)
    : route.assessment(
        jobNanoId as JobNanoId,
        jobCandidate.nanoId as JobCandidateNanoId
      );

  return (
    <>
      <Flex pr={4} pb={4} gap={6} pos="relative" alignItems="center">
        {isDisabled && <ViewJobCandidateDetailsPageOverlay />}

        <NextLinkHelper href={handleGoBack().url} passHref>
          <ButtonWithRef
            as="a"
            h={8}
            size="medium"
            display="flex"
            color="gray.700"
            fontWeight="normal"
            hierarchy="unstyled"
            leftIcon={<ChevronLeftIcon w={5} h={5} />}
            onClick={() => handleGoBack().url}
          >
            Back to {startCase(handleGoBack().label)}
          </ButtonWithRef>
        </NextLinkHelper>

        <HStack
          ml="auto"
          spacing={4}
          flexWrap="wrap"
          alignItems="center"
          divider={<StackDivider />}
        >
          <ViewJobCandidateDetailsHeaderMenuAddJob
            jobCandidate={jobCandidate}
            handleRouteToJob={handleRouteToJob}
          />

          <Button
            h={8}
            size="medium"
            display="flex"
            color="gray.600"
            hierarchy="unstyled"
            leftIcon={<ShareIcon w={5} h={5} />}
            onClick={() => shareJobCandidate(jobCandidate)}
          >
            Share
          </Button>

          <NextLinkHelper href={assessmentUrl} passHref>
            <ButtonWithRef
              h={8}
              as="a"
              size="medium"
              display="flex"
              color="primary.500"
              hierarchy="unstyled"
              leftIcon={<AddPersonIcon w={4} h={4} fill="primary.500" />}
            >
              Nxmoov Profile
            </ButtonWithRef>
          </NextLinkHelper>
        </HStack>

        <Flex gap={3} alignItems="center">
          <Tooltip label="Reminder" textTransform="capitalize" hasArrow>
            <IconButton
              h={8}
              w={8}
              minW={0}
              minH={0}
              value="reminder"
              display="flex"
              aria-label=""
              alignItems="center"
              borderRadius="full"
              justifyContent="center"
              icon={<AlarmIcon w={4} h={4} />}
              hierarchy="tertiary"
            />
          </Tooltip>

          <Tooltip label="Email" textTransform="capitalize" hasArrow>
            <IconButton
              h={8}
              w={8}
              minW={0}
              minH={0}
              value="email"
              display="flex"
              alignItems="center"
              borderRadius="full"
              aria-label="Compose email"
              icon={<EmailIcon w={4} h={4} />}
              onClick={openComposeEmail}
              justifyContent="center"
              hierarchy="tertiary"
            />
          </Tooltip>

          <Tooltip label="Comment" textTransform="capitalize" hasArrow>
            <IconButton
              h={8}
              w={8}
              minW={0}
              minH={0}
              value="comment"
              display="flex"
              alignItems="center"
              borderRadius="full"
              justifyContent="center"
              aria-label="Add comment"
              icon={<MessageIcon w={4} h={4} />}
              onClick={openAddComment}
              hierarchy="tertiary"
            />
          </Tooltip>

          <HStack
            spacing={0}
            overflow="hidden"
            border="1px solid"
            borderColor="gray.300"
            borderRadius="base"
            divider={<StackDivider borderColor="gray.200" />}
          >
            <Button
              h={7}
              px={4}
              hierarchy="tertiary"
              size="extra-small"
              borderRadius="none"
              border="none"
              onClick={onOpenPhoneCallActivity}
              leftIcon={<PhoneIcon w={3} h={3} fill="gray.500" />}
            >
              Call
            </Button>

            <IconButton
              h={7}
              border="none"
              display="flex"
              aria-label=""
              size="extra-small"
              alignItems="center"
              borderRadius="none"
              hierarchy="tertiary"
              justifyContent="center"
              icon={<ChevronDownIcon w={3.5} h={3.5} fill="gray.500" />}
            />
          </HStack>
        </Flex>
      </Flex>

      <Drawer
        size="md"
        isOpen={isOpenPhoneCallActivity}
        onClose={onTogglePhoneCallActivity}
      >
        <DrawerOverlay />
        <DrawerContent p={0}>
          <DrawerBody p={0}>
            {jobCandidate ? (
              <ViewJobCandidateModalPhoneSidebarHelper
                fullWidth
                withoutBorder
                jobCandidate={jobCandidate}
                toggleFollowUpEmail={console.log}
                isSidebarOpen={isOpenPhoneCallActivity}
                sidebarToggle={onTogglePhoneCallActivity}
                personalPhoneNumber={jobCandidate.phone}
                companyPhoneNumber={jobCandidate.companyPhone}
              />
            ) : null}
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
}
