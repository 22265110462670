import {
  CancelJobCandidateAssessmentBffURL,
  CreateJobCandidateAssessmentBffURL,
  FetchJobCandidateAssessmentBffURL,
  FetchJobCandidateAssessmentByNanoIdBffURL,
  FetchJobCandidateAssessmentsBffURL,
  JobCandidateAssessmentItemBffID,
  JobCandidateAssessmentItemBffNanoID,
  ResendJobCandidateAssessmentBffURL,
  UpdateJobCandidateAssessmentBffURL
} from './jobCandidateAssessmentsTypes';

import { BaseBffRoutes } from '../BaseBffRoutes';

export class JobCandidateAssessmentsBffRoutes extends BaseBffRoutes {
  protected static route = 'jobCandidateAssessments';

  static fetchJobCandidateAssessmentsRoute() {
    return this.fetchItemsRoute<FetchJobCandidateAssessmentsBffURL>();
  }

  static fetchJobCandidateAssessmentsIndexRoute() {
    return this.fetchItemsIndexRoute<FetchJobCandidateAssessmentsBffURL>();
  }

  static fetchJobCandidateAssessmentRoute(
    jobCandidateAssessmentItemBffID: JobCandidateAssessmentItemBffID
  ) {
    return this.fetchItemRoute<FetchJobCandidateAssessmentBffURL>(
      jobCandidateAssessmentItemBffID
    );
  }

  static fetchJobCandidateAssessmentIndexRoute(
    jobCandidateAssessmentItemBffNanoID: JobCandidateAssessmentItemBffNanoID
  ) {
    return this.fetchItemIndexRoute<FetchJobCandidateAssessmentBffURL>(
      jobCandidateAssessmentItemBffNanoID
    );
  }

  static fetchJobCandidateAssessmentByNanoIdRoute(
    jobCandidateAssessmentItemBffNanoID: JobCandidateAssessmentItemBffNanoID
  ) {
    return this.fetchItemByNanoIdRoute<FetchJobCandidateAssessmentByNanoIdBffURL>(
      jobCandidateAssessmentItemBffNanoID
    );
  }

  static createJobCandidateAssessmentRoute() {
    return this.createItemRoute<CreateJobCandidateAssessmentBffURL>();
  }

  static updateJobCandidateAssessmentRoute(
    updateJobCandidateAssessmentItemBffID:
      | JobCandidateAssessmentItemBffNanoID
      | JobCandidateAssessmentItemBffID
  ) {
    return this.updateItemRoute<UpdateJobCandidateAssessmentBffURL>(
      updateJobCandidateAssessmentItemBffID
    );
  }

  static cancelJobCandidateAssessmentRoute(
    cancelJobCandidateAssessmentItemBffID: JobCandidateAssessmentItemBffNanoID
  ) {
    return this.postItemRoute<CancelJobCandidateAssessmentBffURL>(
      'cancel',
      cancelJobCandidateAssessmentItemBffID
    );
  }

  static resendJobCandidateAssessmentRoute(
    resendJobCandidateAssessmentItemBffID: JobCandidateAssessmentItemBffNanoID
  ) {
    return this.postItemRoute<ResendJobCandidateAssessmentBffURL>(
      'send_emails',
      resendJobCandidateAssessmentItemBffID
    );
  }
}
