import {
  Box,
  ButtonGroup,
  Flex,
  Stack,
  StackDivider,
  SystemStyleObject
} from '@chakra-ui/react';
import { Button } from '../../../../../../helpers/Button';
import { InputField } from '../../../../../../helpers/forms/formFields/InputField';
import { MultiSelectFieldControl } from '../../../../../../helpers/forms/formFields/MultiSelectField/components/MultiSelectFieldControl';
import { WysiwygFieldControl } from '../../../../../../helpers/forms/formFields/WysiwygField/components/WysiwygFieldControl';
import { AttachIcon } from '../../../../../../icons/AttachIcon';
import { ClockOutlineIcon } from '../../../../../../icons/ClockOutlineIcon';

interface ViewJobCandidateDetailsComposeEmailProps {
  onSend?: () => void;
  onDiscard: () => void;
}

function ViewJobCandidateDetailsComposeEmail({
  onSend,
  onDiscard
}: ViewJobCandidateDetailsComposeEmailProps) {
  return (
    <Stack
      sx={{
        '.rdw-fontsize-wrapper, .rdw-image-wrapper': {
          display: 'none !important'
        },
        '.rdw-fontfamily-wrapper': {
          display: 'none !important'
        }
      }}
    >
      <Stack p={4} bg="white" borderRadius="base" spacing={4}>
        <Stack spacing={4}>
          <Flex gap={4} flexDir="column" flex={1}>
            <Stack
              spacing={2}
              color="gray.600"
              textStyle="body1Regular"
              divider={<StackDivider />}
            >
              <Flex as="label" gap={1} cursor="pointer" alignItems="center">
                <Box as="span">To:</Box>

                <MultiSelectFieldControl
                  size="sm"
                  withImages
                  onChange={console.log}
                  reactSelectStyles={{
                    control: (base: SystemStyleObject) => ({
                      ...base,
                      borderColor: 'transparent',
                      p: 0
                    })
                  }}
                  options={[]}
                />
              </Flex>

              <Flex as="label" gap={1} cursor="pointer" alignItems="center">
                <Box as="span">Cc:</Box>{' '}
                <MultiSelectFieldControl
                  size="sm"
                  withImages
                  onChange={console.log}
                  reactSelectStyles={{
                    control: (base: SystemStyleObject) => ({
                      ...base,
                      borderColor: 'transparent',
                      p: 0
                    })
                  }}
                  options={[]}
                />
              </Flex>

              <Flex as="label" gap={1} cursor="pointer" alignItems="center">
                <Box as="span">Subject:</Box>
                <InputField size="small" borderColor="white" />
              </Flex>
            </Stack>

            <Box h="220px">
              <WysiwygFieldControl
                name=""
                value=""
                defaultValue=""
                onChange={() => {
                  //
                }}
              />
            </Box>
          </Flex>
        </Stack>

        <Flex w="full">
          <Flex gap={4}>
            <Button
              color="gray.600"
              size="small"
              hierarchy="link"
              leftIcon={<AttachIcon color="gray.500" />}
            >
              Attach
            </Button>
            <Button
              color="gray.600"
              size="small"
              hierarchy="link"
              leftIcon={<ClockOutlineIcon color="gray.500" />}
            >
              Schedule
            </Button>
          </Flex>

          <ButtonGroup ml="auto" spacing={2}>
            <Button
              px={4}
              size="small"
              hierarchy="tertiary"
              onClick={onDiscard}
            >
              Discard
            </Button>

            <Button size="small" px={6} onClick={onSend}>
              Send
            </Button>
          </ButtonGroup>
        </Flex>
      </Stack>
    </Stack>
  );
}

export default ViewJobCandidateDetailsComposeEmail;
