import {
  Box,
  Flex,
  Portal,
  Stack,
  StackDivider,
  VStack
} from '@chakra-ui/react';
import { motion } from 'framer-motion';
import useNotificationToast from '../../common/hooks/utils/useNotificationToast/useNotificationToast';
import { CancelIcon } from '../../icons/CancelIcon';
import { Button } from '../Button';
import { Text } from '../Text';
import { NotificationToastItem } from './components/NotificationToastItem';

export default function NotificationToast() {
  const { notifications, removeToast, clearAllToasts } = useNotificationToast();

  return (
    <>
      {notifications && (
        <Portal>
          <motion.div
            initial={{ opacity: 0, x: 20 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: -20 }}
            transition={{ duration: 0.3 }}
          >
            <Box
              pt={5}
              pb={5}
              h="100%"
              w="360px"
              right={5}
              zIndex={9999}
              position="fixed"
              pointerEvents="none"
            >
              <Stack
                p={4}
                bg="white"
                pointerEvents="all"
                boxShadow="0 4px 10px -4px rgba(0, 0, 0, 0.1), 0 0 10px 10px rgba(0, 0, 0, 0.04)"
                divider={<StackDivider />}
                rounded="md"
                spacing={0}
              >
                <Flex pl={2} pb={2.5} justify="space-between" align="center">
                  <Text fontWeight="bold">Reminders</Text>

                  <Button
                    boxShadow="none"
                    hierarchy="ghost"
                    rightIcon={<CancelIcon w={5} h={5} />}
                    onClick={clearAllToasts}
                    color="gray.600"
                    size="small"
                  >
                    Dismiss All
                  </Button>
                </Flex>

                <VStack
                  spacing={0.5}
                  divider={<StackDivider borderColor="gray.200" />}
                  align="stretch"
                >
                  {notifications.map((item) => (
                    <NotificationToastItem
                      key={item.id}
                      notification={item}
                      removeNotification={removeToast}
                    />
                  ))}
                </VStack>
              </Stack>
            </Box>
          </motion.div>
        </Portal>
      )}
    </>
  );
}
